@font-face {
	font-family: 'Gigantic FS'; /*Can be any text*/
	src: local('Gigantic FC'),
		url('./GiganticFS/GiganticFs.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope'; /*Can be any text*/
	src: local('Manrope'), url('./Manrope/Manrope.ttf') format('truetype');
}
